import {
  Alert,
  Container,
  ErrorPage,
  FabSpeedDial,
  Icon,
  Info,
  Loading,
  NotificationActions,
  withAuth
} from '@elotech/components';
import {
  ContestacaoSectionForm,
  PessoasQuickView
} from 'itbi-common/components';
import {
  DeclaracaoService,
  UploadService,
  withService
} from 'itbi-common/service';
import { DiffUtils, FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { Roles } from '../../roles';
import DeclaracaoUtils from '../../utils/DeclaracaoUtils';
import { renderFabButtons } from './DeclacacaoActions';
import DeclaracaoItbiHistory from './history/DeclaracaoItbiHistory';
import ResumoDeclaracao from './ResumoDeclaracao';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

const labelsChildren = ['segmentos', 'vendedores', 'anuentes', 'proprietarios'];

class DeclaracaoItbiViewPage extends React.Component {
  static propTypes = {
    declaracaoService: PropTypes.shape({
      findDeclaracaoItbiById: PropTypes.func.isRequired,
      notificarCidadao: PropTypes.func.isRequired,
      transferir: PropTypes.func.isRequired,
      cancelar: PropTypes.func.isRequired,
      getHistory: PropTypes.func.isRequired
    }).isRequired,
    uploadService: PropTypes.shape({
      getUrlDownloadArquivoAvulsoDeclaracao: PropTypes.func.isRequired,
      downloadFileS3: PropTypes.func.isRequired
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func.isRequired
    }).isRequired,
    showNotification: PropTypes.func.isRequired
  };
  state = {
    declaracaoItbi: undefined,
    loading: false,
    pessoaQuickView: undefined,
    history: undefined,
    errorOnLoad: undefined
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.onGetDeclaracao(id);
  }

  onGetDeclaracaoSuccess = response => {
    this.setState({
      declaracaoItbi: response.data
    });
    this.getHistory(response.data.id);
  };

  onGetDeclaracaoError = error => {
    this.setState({
      ...this.state,
      errorOnLoad: {
        status: error.response?.status,
        message: error.response?.statusText
      }
    });
  };

  onGetDeclaracao = id => {
    this.props.declaracaoService
      .findDeclaracaoItbiById(id)
      .then(this.onGetDeclaracaoSuccess)
      .catch(this.onGetDeclaracaoError);
  };

  onPrintCertidaoQuitacao = declaracao => {
    DeclaracaoUtils.downloadCertidaoQuitacao(
      this.props.declaracaoService.imprimirCertidaoQuitacao,
      declaracao,
      state => this.setState(state)
    );
  };

  onPrintCertidaoIsencao = declaracao => {
    DeclaracaoUtils.printCertidaoIsencao(
      this.props.declaracaoService.printCertidaoIsencao,
      declaracao,
      state => this.setState(state)
    );
  };

  getHistory = id => {
    this.props.declaracaoService
      .getHistory(id)
      .then(historyResponse => this.parseDiff(historyResponse.data));
  };

  identity = (a, b) => (!a || !b ? false : a.id === b.id);
  ignorePaths = (path, key) => labelsChildren.includes(key);

  parseDiff = history => {
    let historyDiff = DiffUtils.parseDiff(history, {}, this.ignorePaths);

    historyDiff = DiffUtils.parseDiffChildren(
      historyDiff,
      labelsChildren,
      this.identity
    );

    this.setState({
      history: historyDiff
    });
  };

  motivoCancelamentoValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Descreva o motivo do Cancelamento!');
      }
    });
  };

  onCancelar = () => {
    const {
      match: { params },
      declaracaoService
    } = this.props;

    DeclaracaoUtils.cancelar(
      motivo => declaracaoService.cancelar(params.id, motivo),
      state => this.setState(state),
      this.motivoCancelamentoValidator
    ).then(result => {
      if (result && result.value) {
        declaracaoService
          .indeferir(params.id, { motivo: result.value })
          .then(() => this.onGetDeclaracao(params.id));
      }
    });
  };

  onEstornar = () => {
    const {
      match: { params },
      declaracaoService
    } = this.props;

    DeclaracaoUtils.estornar(
      motivo => declaracaoService.cancelar(params.id, motivo),
      state => this.setState(state)
    ).then(() => this.onGetDeclaracao(params.id));
  };

  onNotificar = () => {
    const {
      match: { params },
      declaracaoService
    } = this.props;

    DeclaracaoUtils.notificar(
      () => declaracaoService.notificarCidadao(params.id),
      state => this.setState(state)
    ).then(() => this.onGetDeclaracao(params.id));
  };

  onGerarDebito = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/declaracoes-itbi/${id}/confirmacao-gerar-debito`);
  };

  onEdit = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/declaracoes-itbi/${id}`);
  };

  onTranferirResponsavel = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/declaracoes-itbi/${id}/transferencia`);
  };

  onAnalizeContestacao = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/declaracoes-itbi/${id}/analisar-contestacao`);
  };

  onTransferir = () => {
    const {
      match: { params },
      declaracaoService
    } = this.props;

    DeclaracaoUtils.transferir(
      () => declaracaoService.transferir(params.id),
      state => this.setState(state)
    ).then(() => this.onGetDeclaracao(params.id));
  };

  onTransferirSemPagamento = () => {
    const {
      match: { params },
      declaracaoService
    } = this.props;

    DeclaracaoUtils.transferir(
      () => declaracaoService.transferirSemPagamento(params.id),
      state => this.setState(state)
    ).then(() => this.onGetDeclaracao(params.id));
  };

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () => {
    this.setState({
      pessoaQuickView: undefined
    });
  };

  onDownloadDocumentoAvulso = documento => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, documento.nome)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onDownloadDocumentoImovel = (documento, nomeArquivo) => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;
    uploadService
      .getUrlDownloadS3Declaracao({
        declaracao: declaracaoItbi.id,
        documento: documento.documentoItbi.id,
        arquivo: nomeArquivo
      })
      .then(response => {
        window.open(response.data.url, '_blank');
      });
  };

  onDownloadArquivoContestacao = urlArquivo => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, urlArquivo)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onGenerateBoleto = () => {
    const { id } = this.props.match.params;
    this.setState({
      loading: true
    });

    this.props.declaracaoService
      .gerarBoleto(id)
      .then(response => {
        window.open(response.data.urlBoleto, '_blank');
        this.setState({ declaracaoItbi: response.data, loading: false });
      })
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao gerar o boleto ' }, error);
        this.setState({ loading: false });
      });
  };

  onApagarGuiasPagamento = () => {
    const { id } = this.props.match.params;
    Alert.question({
      title: 'Deseja realmente apagar a guia de pagamento?'
    }).then(result => {
      if (result.value) {
        this.setState({
          loading: true
        });

        this.props.declaracaoService
          .apagarGuiaPagamento(id)
          .then(response => {
            Alert.info({
              title: 'A guia de pagamento foi excluída com sucesso.'
            });
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível apagar a guia de pagamento' },
              error
            );
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  };

  retificar = event => {
    event.preventDefault();
    const { history } = this.props;
    const { declaracaoItbi } = this.state;

    this.setState({
      submitting: true
    });

    DeclaracaoService.retificar(declaracaoItbi.id)
      .then(() => {
        this.setState({
          submitting: false
        });
        history.replace(`/declaracoes-itbi/${declaracaoItbi.id}`);
      })
      .catch(error => {
        this.setState({ error: error, submitting: false });
        Alert.error({ title: 'Falha ao retificar declaração!' }, error);
      });
  };

  mostrarMensagemRetificacaoAntesDeNotificarCidadao = () => {
    const {
      situacaoItbi,
      tipoItbi,
      valorIsencao,
      valorTransacaoItbi
    } = this.state.declaracaoItbi;
    return (
      situacaoItbi.name === 'AGUARDANDO_NOTIFICACAO' &&
      tipoItbi.isencao &&
      valorTransacaoItbi === valorIsencao && (
        <Info classes="warning mb-xs">
          Analise as informações antes de notificar o cidadão. Caso algo esteja
          errado{' '}
          <a
            data-test-id="link-retificar"
            href="#retificar"
            onClick={this.retificar}
          >
            clique aqui
          </a>{' '}
          e faça a análise novamente.
        </Info>
      )
    );
  };

  render() {
    const {
      declaracaoItbi,
      loading,
      pessoaQuickView,
      history,
      errorOnLoad
    } = this.state;
    const {
      currentUser,
      auth: { hasRole }
    } = this.props;
    const hasPermission = hasRole(Roles.acesso_prioritario.name);

    const actions = {
      onEdit: this.onEdit,
      onGenerateDebito: this.onGerarDebito,
      onTransferir: this.onTransferir,
      onTransferirSemPagamento: this.onTransferirSemPagamento,
      onNotify: this.onNotificar,
      onReverse: this.onEstornar,
      onTransferirResponsavel: this.onTranferirResponsavel,
      onCancelar: this.onCancelar,
      onDownloadQuitacao: this.onPrintCertidaoQuitacao,
      onGenerateBoleto: this.onGenerateBoleto,
      onApagarGuiasPagamento: this.onApagarGuiasPagamento,
      onPrintCertidaoIsencao: this.onPrintCertidaoIsencao,
      onAnalizeContestacao: this.onAnalizeContestacao
    };

    const additionalOptions = {
      hasRole: hasRole,
      currentUser
    };

    if (errorOnLoad) {
      return (
        <ErrorPage status={errorOnLoad.status} message={errorOnLoad.message} />
      );
    }

    if (!declaracaoItbi) {
      return (
        <CenteredIcon
          size="4x"
          icon="spinner"
          spin
          primary
          style={{ margin: '10% 45%' }}
        />
      );
    }
    return (
      <Container title="Resumo da Declaração ITBI" icon="file-signature">
        <Loading loading={loading} />
        {hasPermission === true && declaracaoItbi.prioritario === true ? (
          <Info classes="warning mb-xs">
            {`Motivo da Priorização: ${declaracaoItbi.motivoPrioritario}`}
          </Info>
        ) : null}
        {this.mostrarMensagemRetificacaoAntesDeNotificarCidadao()}
        <ResumoDeclaracao
          declaracao={declaracaoItbi}
          onViewPessoa={this.onViewPessoa}
          onDownloadDocumentoAvulso={this.onDownloadDocumentoAvulso}
          onDownloadDocumentoImovel={this.onDownloadDocumentoImovel}
        />
        {history && <DeclaracaoItbiHistory revisions={history} />}
        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />
        {declaracaoItbi?.contestacao && (
          <ContestacaoSectionForm
            contestacao={declaracaoItbi.contestacao}
            onDownload={this.onDownloadArquivoContestacao}
            isVisibleAllInformations={true}
          />
        )}
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          {renderFabButtons(declaracaoItbi, actions, additionalOptions, {
            hasRole
          })}
        </FabSpeedDial>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

const enhancers = FunctionUtils.compose(
  withService({
    declaracaoService: DeclaracaoService,
    uploadService: UploadService
  }),
  connect(mapStateToProps, {
    showNotification: NotificationActions.showNotification
  })
);

const ComponentWithAuth = withAuth(enhancers(DeclaracaoItbiViewPage));

const EnhancedComponent = withRouter(ComponentWithAuth);

export { EnhancedComponent as default, DeclaracaoItbiViewPage };
